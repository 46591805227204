/* You can add global styles to this file, and also import other style files */
@import 'ngx-tabset/ngx-tabset.css';
@font-face {
    font-family: droid-sans;
    src: url(./assets/fonts/DroidSans.ttf) format("truetype");
}

@font-face {
    font-family: droid-san-bold;
    src: url(./assets/fonts/DroidSans-Bold.ttf) format("truetype");
}

@font-face {
    font-family: SourceSansPro-Regular;
    src: url(./assets/fonts/SourceSansPro-Regular.otf) format("truetype");
}

@font-face {
    font-family: SourceSansPro-Bold;
    src: url(./assets/fonts/SourceSansPro-Bold.otf) format("truetype");
}

@font-face {
    font-family: SourceSansPro-Light;
    src: url(./assets/fonts/SourceSansPro-Light.otf) format("truetype");
}

:root {
    --primary-color: var(--primary-color);
    --background-color:  var(--background-color);
    --header-background: var(--header-background);
    --footer-background: var(--footer-background);
    --icons-color : var(--icons-color);
    --text-color: var(--text-color);
    --tabs-color: var(--tabs-color);
    --side-menu-background:  var(--side-menu-background);
    --side-footer-background : var(--side-footer-background);
    --loginLogo:  var(--loginLogo);
    --headerLogo:  var(--headerLogo);
  }

  body {
    font-family: SourceSansPro-Regular;
    padding: 0;
    margin: 0;
    background: var(--background-color);
  }



aside {
    background-color: var(--side-menu-background) !important;
    padding: 0px !important;
    max-width: 70% !important;
    width: 100% !important;
}

.menu-button button {
    border: none;
    background: none;
    color: white;
    font-size: 18px;
    outline: none;
}

.triangle-button {
    text-align: center;

    button {
        background: none;
        border: none;

        img {
            width: 100px;
        }
    }

    button:focus {
        outline: none;
    }
}

.error-container {
    color: white;
    font-size: 18px;
    text-align: center;
    font-style: italic;
}

.credentials-field {
    margin-bottom: 40px;

    input {
        border: none;
        border-bottom: 1px solid var(--text-color);
        background-color: transparent;
        width: 100%;
        padding: 5px;
        color: var(--text-color);
        text-align: center;
        font-size: 15px;

    }

    input:focus {
        outline: none;
    }

    ::-webkit-input-placeholder {
        text-align: center;
        font-weight: bold;
    }

    :-moz-placeholder {
        /* Firefox 18- */
        text-align: center;
        font-weight: bold;
    }

    ::-moz-placeholder {
        /* Firefox 19+ */
        text-align: center;
        font-weight: bold;
    }

    :-ms-input-placeholder {
        text-align: center;
        font-weight: bold;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: #5c5c5d;
    }
}

/* update style 4-2019 (by: eman) */
.games .slick-initialized .slick-slide {
    padding: 10px;
}

.game-container .image-large-container img {
    height: 250px !important;
}

.game-container {
    padding-right: 0 !important;
}


.footer {
    border-top: 0;
    padding-top: 5px;
    opacity: 1 !important;
}

.detail-container {
    color: #000 !important;

    .game-banner img {
        height: auto !important;
    }
}


.error-container {
    color: red;
}

aside {
    z-index: 999 !important;
}
.side-footer .container{
    .menu-items {
        // background-color: #383b40;
        background-color: var(--side-footer-background) !important;
    }
}

.game-reviews textarea {
    color: #000 !important;
}

/* update style 4-2019 (by: eman) */

/* start direction rtl */
.rtl {
    direction: rtl;
    text-align: right;
    font-family: 'Droid Arabic Kufi', sans-serif !important;
    .header-wrapper{
        .icon{
            text-align: left !important;
        }
    }

    *:not(i) {
        font-family: 'Droid Arabic Kufi', sans-serif !important;
    }

    .slick-list {
        direction: ltr;
        text-align: right;
    }

    .game-container {
        direction: rtl;
        text-align: right;
    }

    .result-search .text {
        text-align: left;
    }

    .aside.left {
        left: auto;
        right: 0;
    }

    .body .contents .content img {
        float: left !important;
    }

    .dropdown-menu {
        left: 0% !important;
        top: 25px !important;
    }

    .fa-arrow-left {
        right: 21px;
        position: relative;
        -webkit-transform: rotateY(180deg);
        -moz-transform: rotateY(180deg);
        -ms-transform: rotateY(180deg);
        -o-transform: rotateY(180deg);
        transform: rotateY(180deg);
    }

    .result-category .games .text p i {
        float: left !important;
    }

    // .menu-button {
    //     z-index: 2;
    //     text-align: left;
    //     position: relative;
    //     outline: none;
    //     background: none;
    // }

    .body .content form div {
        text-align: right !important;
    }
}

/* end direction rtl */

/*TABSET*/@at-root.nav-tabset:not(.disable-style) {
  width: 100%;
  list-style: none;
  padding-right: 20px;
  padding-left :19px;
  display: flex;
  align-items: center;
  text-align: center;
}
.nav-tabset:not(.disable-style) .nav-tab {
    width: 100%;
    padding: 10px;
    border-bottom: solid 1px transparent;
    transition: border 200ms ease-out;
    padding-right: 20px;
    padding-left: 25px;
    align-items: center;
    text-align: center;
    color: gray;
}
.nav-tabset:not(.disable-style) .nav-tab.active {
      border: #8ce6e6 1px solid;
      background-color: #8ce6e6;

}
.nav-tabset:not(.disable-style) .nav-tab.disabled {
      opacity: 0.5;
}
.nav-tabset:not(.disable-style) .nav-tab:not(.active):not(.disabled) {
      cursor: pointer;
      border: #8ce6e6 1px solid;
}

.dark-balck-background{
    background-color: #2e3035; //needs to be added to themes Files as a variable
    color:white;
}
.ltr .exit-nav {
  float: right;
}
.rtl .exit-nav {
  float: left !important;
}
.catsC {
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
    width: 0px;
    background: transparent;
  }
}
.catTabs {
  display: flex;
  margin: 10px 15px;
  height: calc(40px + (80 - 40) * ((100vw - 300px) / (1600 - 300)));
  justify-content: flex-start;
  align-content: center;
  overflow: hidden;
  overflow-x: scroll;

  .tab {
    height: calc(30px + (60 - 30) * ((100vw - 300px) / (1600 - 300)));
    padding: 4px 10px;
    border: 1px solid white;
    border-radius: 22px;
    margin: 0 5px;
    color: var(--text-color);
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: calc(12px + (20 - 12) * ((100vw - 300px) / (1600 - 300)));
    line-height: normal;
    display: flex;
    justify-content:center;
    align-items:center;
    text-decoration: none;
  }

  .tab.active-cat{
    color: var(--primary-color);
    border-color: var(--primary-color);
  }
  ngx-spinner .overlay{
    min-height: 123.2vh !important;
  }
}
